/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line
import React from 'react';
import { Link } from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {countries} from '../constants/countryList';

export const parseJSONSafely = (str) => {
  try {
    return JSON.parse(str);
  }
  catch (e) {
    console.error(e);
    // Return a default object, or null based on use case.
    return null
  }
}

export const isJsonParsable = (str) => {
  try {
    JSON.parse(str);
  }
  catch (e) {
    return false;
  }
  return true;
}

export const useQueryString = () => {
  return new URLSearchParams(useLocation().search);
}

export const urlSearchParams = (search) => {
  return new URLSearchParams(search);
}

export const  useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const stringToBoolean = (string) =>{
  switch(string.toLowerCase().trim()){
      case "true": 
      case "yes": 
      case "1": 
        return true;

      case "false": 
      case "no": 
      case "0": 
      case null: 
        return false;

      default: 
        return Boolean(string);
  }
}

export const capitaliseFirstCase = (item) => {
  let tempValue = "";
  if (item && (typeof item === "string")) {
    let trimmedItem = item.trim();
    tempValue = trimmedItem.charAt(0).toUpperCase() + trimmedItem.slice(1);
  }
  return tempValue;
}

export const removeLastNthParamFrmUrl = (url, index) => {
  let baseUrl = "/";

  if (index && url && url !== "") {
      let tempResult = url.split('/');
      tempResult.splice(-index, index)
      baseUrl = tempResult.join('/');
  }

  return baseUrl
}

export const updateAttachmentData = (attach, tempAttach) => {
  let newAttachment = [];
  if (attach && attach !== "" && tempAttach) {
    let attachList = parseJSONSafely(attach) ? parseJSONSafely(attach) : [];
    attachList.forEach(att => {
      let tempAtt = tempAttach.find(t => t.name === att.name);
      if (tempAtt) {
        let newAtt = {
          ...att,
          ...tempAtt
        }
        newAttachment.push(newAtt);
      } else {
        newAttachment.push(att);
      }
    })
  }
  return JSON.stringify(newAttachment);
}

export const updateAttachmentDataNotStringified = (attach, tempAttach) => {
  let newAttachment = [];
  if (attach && attach !== "" && tempAttach) {
    let attachList = attach && attach.length > 0 ? attach : [];
    attachList.forEach(att => {
      let tempAtt = tempAttach.find(t => t.name === att.name);
      if (tempAtt) {
        let newAtt = {
          ...att,
          ...tempAtt
        }
        newAttachment.push(newAtt);
      } else {
        newAttachment.push(att);
      }
    })
  }
  return newAttachment;
}

export const getSectionName = (url, index) => {
  let section = "";
  if (index && url && url !== "") {
      let tempResult = url.split('/');
      let urlLength = tempResult.length;
      section = tempResult[urlLength-index];
  }
  return section
}

export const createQueryObject = (url, index) => {
  let queryObject = {};
  if (index && url && url !== "") {
      let sectionName = getParentSection(url, index);
      let sectionId = getParentId(url, index);
      if (sectionName && sectionId) {
          switch (sectionName) {
              case "asset-group":
                  queryObject = { ag_id: sectionId };
                  break;
              case "installation":
                  queryObject = { installation_id: sectionId };
                  break;
              case "barrier":
                  queryObject = { bar_id: sectionId };
                  break;
              case "sece":
                  queryObject = { sece_id: sectionId };
                  break;
              case "mah":
                  queryObject = { mah_id: sectionId };
                  break;
              case "performance-standard":
                  queryObject = { ps_id: sectionId };
                  break;
              case "verification-activity":
                  queryObject = { va_id: sectionId };
                  break;
              default:
                  break;
          }
      }      
  } 
  return queryObject;
}

export const getParentSection = (url, index) => {
  let sectionName = "";
  if (index && url && url !== "") {
      let tempResult = url.split('/');
      let urlLength = tempResult.length;
      sectionName = tempResult[urlLength - index];
  }
  return sectionName
}

export const getParentId = (url, index) => {
  let sectionId = "";
  if (index && url && url !== "") {
      let tempResult = url.split('/');
      let urlLength = tempResult.length;
      sectionId = tempResult[urlLength - (index - 1)];
  }
  return sectionId
}

//Confidence Level
export const confidenceLevelManager = (variableObject, criticality) => {
  let confidence;
  if (variableObject && criticality) {
    let percent = percentCalculator(variableObject, criticality);
    confidence = confidenceLevelCalculator(criticality, percent);
  }
  return confidence;
}

function percentCalculator(variableData, criticality) {
  let percent = 0;
  let baseline = baselineValue(criticality);
  let incrDecrValue = incrementDecrementValue(criticality);

  if (variableData.vaCompleted) {
    if (variableData.vaFindingsOpen) {
      percent = baseline + (incrDecrValue * 2);
    } else {
      if (variableData.psVaChanges) {
        percent = baseline + (incrDecrValue * 2);
      } else {
        if (variableData.seceModified) {
          percent = baseline + (incrDecrValue * 2);
        } else {
          if (variableData.vaMoreThanTwoMLFindingsRaised) {
            percent = baseline + (incrDecrValue * 2);
          } else {
            if (variableData.vaOneMLFindingRaised) {
              percent = baseline + (incrDecrValue * 1);;
            } else {
              percent = baseline - (incrDecrValue * 1);
            }
          }
        }
      }
    }
  } else {
    percent = baseline + (incrDecrValue * 2);
  }

  return percent;
}

function confidenceLevelCalculator(criticality, percent) {
  let confidence = {text: "Baseline", value: 0 };

  switch (criticality) {
    case 1:
    case "A":
    case "High":
      confidence = criticalityHigh(percent);
      break;
    case 2:
    case "B":
    case "Medium":
      confidence = crticalityMedium(percent);
      break;
    case 3:
    case "C":
    case "Low":
      confidence = crticalityLow(percent);
      break;
    default:
      break;
  }
  return confidence;
}

function baselineValue(criticality) {
  let baseline = 0;
  switch (criticality) {
    case 1 :
    case "A":
    case "High":
      baseline = 40;
      break;
    case 2:
    case "B":
    case "Medium":
      baseline = 20;
      break;
    case 3:
    case "C":
    case "Low":
      baseline = 10;
      break;
    default:
      break;
  }
  return baseline;
}

function incrementDecrementValue(criticality) {
  let value = 0;
  switch (criticality) {
    case 1:
    case "A":
    case "High":
      value = 10;
      break;
    case 2:
    case "B":
    case "Medium":
      value = 5;
      break;
    case 3:
    case "C":
    case "Low":
      value = 2.5;
      break;
    default:
      break;
  }
  return value;
}

function criticalityHigh(percent) {
  let confidenceLevel = {};
  switch (percent) {
    case (40):
      confidenceLevel = { text: "Baseline", value: 0 };
      break;
    case (20):
      confidenceLevel = { text: "Confidence +2", value: +2 };
      break;
    case (30):
      confidenceLevel = { text: "Confidence +1", value: +1 };
      break;
    case (50):
      confidenceLevel = { text: "Confidence -1", value: -1};
      break;
    case (60):
      confidenceLevel = { text: "Confidence -2", value: -2 };
      break;
    default:
      break;
  }
  return confidenceLevel;
}

function crticalityMedium(percent) {
  let confidenceLevel = {};
  switch (percent) {
    case (20):
      confidenceLevel = { text: "Baseline", value: 0 };
      break;
    case (10):
      confidenceLevel = { text: "Confidence +2", value: +2 };
      break;
    case (15):
      confidenceLevel = { text: "Confidence +1", value: +1 };
      break;
    case (25):
      confidenceLevel = { text: "Confidence -1", value: -1};
      break;
    case (30):
      confidenceLevel = { text: "Confidence -2", value: -2 };
      break;
    default:
      break;
  }
  return confidenceLevel;
}

function crticalityLow(percent) {
  let confidenceLevel = {};
  switch (percent) {
    case (10):
      confidenceLevel = { text: "Baseline", value: 0 };
      break;
    case (5):
      confidenceLevel = { text: "Confidence +2", value: +2 };
      break;
    case (7.5):
      confidenceLevel = { text: "Confidence +1", value: +1 };
      break;
    case (12.5):
      confidenceLevel = { text: "Confidence -1", value: -1};
      break;
    case (15):
      confidenceLevel = { text: "Confidence -2", value: -2 };
      break;
    default:
      break;
  }
  return confidenceLevel;
}

//Sample data required for Confidence Level Calculation
//const variableData = {
//  vaCompleted : true,
//  vaFindingsOpen : false,
//  vaOneMLFindingRaised : false,
//  vaMoreThanTwoMLFindingsRaised : true,
//  psVaChanges : false,
//  seceModified : false
//}

export const createUserTypeAndRoleObject = (authContext) => {
  let userTypeAndRoleObject = { userType: "", userRole: "", userDhId: "", userName: "" }
  if (authContext && authContext.user) {
    let user = authContext.user;
    const { user_type_display_name = "" } = user.user_type ? user.user_type : {};
    const { user_role_display = "" } = user.user_role ? user.user_role : {};
    const { _id = "", dh_name = "" } = user.user_duty_holder ? user.user_duty_holder : {};
    const {_id:userId, user_fname = "", user_lname = "" } = user;

    userTypeAndRoleObject = { 
      userType: user_type_display_name, 
      userRole: user_role_display, 
      userDhId: _id, 
      userDhName: dh_name,
      userId:userId, 
      userName: `${user_fname} ${user_lname}` }
  }
  return userTypeAndRoleObject;
}

export const formatDateToLocaleDateString = (date) => {
  try {
    if (date && date !== "") {
      let expiryDate = new Date(date);
      let options = { year: 'numeric', month: 'long', day: 'numeric' };
      let dateString = expiryDate.toLocaleDateString('en-GB', options);
      return dateString;
    }
  }
  catch (e) {
    console.error(e);
    // Return a default object, or null based on use case.
    return "";
  }
}

export const formatDateToShortMonthString = (date) => {
  let dateString = "";
  if (date && date !== "") {
    let expiryDate = new Date(date);
    let options = { year: 'numeric', month: 'short', day: 'numeric' };
    dateString = expiryDate.toLocaleDateString('en-GB', options);
  }
  return dateString;
}

export const formatToShortDate = (date) => {
  let dateString = "";
  if (date && date !== "") {
    let expiryDate = new Date(date);
    let options = { year: 'numeric', month: 'long' };
    dateString = expiryDate.toLocaleDateString('en-GB', options);
  }
  return dateString;
}

export const getCountryById = (countryId) => {
  let country = {};
  if (countryId && countryId !== "") {
      let intId = parseInt(countryId);
      country = countries.find(c => c.id === intId);
  }
  return country;
}

export const truncateString = (str, num) => {
  if (str && str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

export const valueFromInfoMatrixLevel = (level) => {
  let value = "N/A";
  if (level && level !== "") {
    let infomatrixLevelArray = level.split(':');
    let infomatrixLevelValue = infomatrixLevelArray[1];
    if (!infomatrixLevelValue ||
      infomatrixLevelValue === "") {
      return value;
    }
    value = infomatrixLevelValue;
  }
  return value;
}

export const booleanToYesNo = (value) => {
  switch (value) {
    case true:
      return "Yes";
    case false:
      return "No";
    default:
      return "N/A";
  }
}

export const processReportCompletedSample = (input) => {
  switch (input) {
    case "N/A":
    case "N/A%":
      return "N/A";
    default:
      if (typeof input === "string") {
        //return input.replace(/%+/g, '') + "%";
        return input.replace(/%+/g, '');
      } else {
        return "N/A";
      }
  }
}

export const getBreadCrumbHeader = (param) => {
  const { userTypeAndRoleObject, drDnDutyholder, drDnInstallation, drDnSece, section, title } = param ?? {};
  if (userTypeAndRoleObject) {
    if (userTypeAndRoleObject.userType === "ABS" && drDnDutyholder.id && drDnInstallation.id) {
      return <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">{section}</Link> {title !==""? `- ${title} -`: ""} Duty Holder: {drDnDutyholder.name} - Installation: {drDnInstallation.name}</h6>;
    } else if (userTypeAndRoleObject.userType === "Duty Holder" && !drDnInstallation.id) {
      return <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">{section}</Link> {title !==""? `- ${title} -`: ""} Duty Holder: {userTypeAndRoleObject.userDhName}</h6>;
    } else if (userTypeAndRoleObject.userType === "Duty Holder" && drDnInstallation.id) {
      return <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">{section}</Link> {title !==""? `- ${title} -`: ""} Duty Holder: {userTypeAndRoleObject.userDhName} - Installation: {drDnInstallation.name}</h6>;
    } else if (!drDnDutyholder.id && !drDnSece.id && !drDnInstallation.id && userTypeAndRoleObject.userType === "ABS") {
      return <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">{section}</Link> {title !==""? `- ${title} -`: ""} Duty Holder / Installation: All</h6>;
    }
  }
  return <></>;
}

export const formatDate = (dateString) => {
  const dateParts = dateString.split("-");
  const day = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]);
  const year = parseInt(dateParts[2]);

  // Create a new Date object (month is 0-based, so we subtract 1 from the month value)
  const date = new Date(year, month - 1, day);

  // Format the date using toLocaleDateString with the desired options
  // const formattedDate = date.toLocaleDateString("en-US", {
  //   day: "numeric",
  //   month: "long",
  //   year: "numeric",
  // });

  const formattedDate =  formatDateToShortMonthString(date)

  return formattedDate;
}

export const splitStringIntoArray = (inputString) => {
  return inputString.split("-").map((part) => part.trim());
}

export const  concatenateProperty = (arr, propertyName) => {
  if (!Array.isArray(arr) || typeof propertyName !== 'string') {
    return 'N/A';
  }
  const concatenated =arr.map(obj => obj[propertyName]).join(', ');
  return concatenated || 'N/A';
}

export const  removeEmptyProperties = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (value === "" || (Array.isArray(value) && value.length === 0)) {
        delete obj[key];
      } else if (typeof value === "object") {
        // Recursively remove empty properties in nested objects
        removeEmptyProperties(value);
      }
    }
  }
}

export const  getFirstElementOrObject = (input) => {
  if (Array.isArray(input)) {
    if (input.length > 0) {
      return input[0];
    } else {
      return null;
    }
  } else if (typeof input === 'object' && input !== null) {
    return input;
  } else {
    return null; 
  }
}

export const  sortDatesAscendingOrder = (dates) => {
  // Convert date strings to Date objects
  const dateObjects = dates.map(dateStr => new Date(dateStr));

  // Sort date objects in ascending order
  const ascendingOrder = [...dateObjects].sort((a, b) => a - b);

  // Convert sorted date objects back to date strings
  const ascendingDates = ascendingOrder.map(date => date.toISOString().split('T')[0]);

  return ascendingDates;
}

export const  sortDatesDescendingDates = (dates) => {
  // Convert date strings to Date objects
  const dateObjects = dates.map(dateStr => new Date(dateStr));
  
  // Sort date objects in descending order
  const descendingOrder = [...dateObjects].sort((a, b) => b - a);

  // Convert sorted date objects back to date strings
  const descendingDates = descendingOrder.map(date => date);

  return descendingDates;
}

export const  adjustTextNode = (textNode) => {
  // Check if the input is an array
  if (!Array.isArray(textNode)) {
    //console.error("Invalid input: Expected an array.");
    return []; // Return an empty array as fallback
  }

  // Process the array
  return textNode.map((node) => {
    // Validate that each node has a `text` property
    if (typeof node.text !== 'string') {
      //console.warn("Invalid node: Missing or invalid 'text' property.", node);
      return node; // Return the unmodified node
    }

    // Replace empty space with a new line
    if (node.text === ' ') {
      return { ...node, text: '\n' };
    }

    return node;
  });
}

